import React from 'react'
import styled from 'styled-components'
import Img from 'gatsby-image'
import { FaTwitterSquare, FaLinkedin, FaGlobe } from 'react-icons/fa'
import RichTextRenderer from 'components/RichTextRenderer'
import { sizes } from 'styles'

const StyledSpeaker = styled.div`
  margin-bottom: 40px;
  line-height: 26px;
`

const SpeakerInfo = styled.div`
  display: flex;
  flex-direction: row;
  align-items: center;
`

const Name = styled.h4`
  margin: 0;
  margin-bottom: 8px;
  font-size: 22px;
`

const ImageContainer = styled.div`
  width: 120px;
  margin-right: 20px;
  margin-bottom: 20px;
  filter: grayscale(100%);

  > .gatsby-image-wrapper {
    border-radius: 50%;
  }

  @media ${sizes.tabletPortrait} {
    width: 150px;
    margin-right: 30px;
    margin-bottom: 0;
  }
`

const Links = styled.div`
  display: flex;
  align-items: center;
  margin-top: 1em;

  > a {
    color: ${({ theme }) => theme.color.tertiary};
    margin-right: 10px;

    > svg {
      width: 32px;
      height: 32px;
    }
  }
`

const WebPageIcon = styled.div`
  width: 28px;
  height: 28px;
  margin-left: 1px;
  margin-top: -5px;
  border-radius: 3px;
  background-color: ${({ theme }) => theme.color.tertiary};
  color: ${({ theme }) => theme.color.white};
  display: inline-flex;
  align-items: center;
  justify-content: center;

  > svg {
    width: 20px;
    height: 20px;
  }
`

const Speaker = ({ speaker }) => {
  const {
    name,
    picture,
    title,
    company,
    description,
    linkedInLink,
    twitterLink,
    webPageLink,
  } = speaker
  return (
    <StyledSpeaker>
      <SpeakerInfo>
        {picture && (
          <ImageContainer>
            <Img
              fluid={{
                ...picture.fluid,
                aspectRatio: 1 / 1,
              }}
            />
          </ImageContainer>
        )}
        <div>
          <Name>{name}</Name>
          {title && <div>{title}</div>}
          <div>
            <b>{company}</b>
          </div>
        </div>
      </SpeakerInfo>
      {description?.json && <RichTextRenderer richText={description?.json} />}
      <Links>
        {linkedInLink && (
          <a href={linkedInLink}>
            <FaLinkedin />
          </a>
        )}
        {twitterLink && (
          <a href={twitterLink}>
            <FaTwitterSquare />
          </a>
        )}
        {webPageLink && (
          <a href={webPageLink}>
            <WebPageIcon>
              <FaGlobe />
            </WebPageIcon>
          </a>
        )}
      </Links>
    </StyledSpeaker>
  )
}

export default Speaker
