import React from 'react'
import styled, { css } from 'styled-components'
import { graphql } from 'gatsby'
import { useDispatch, useSelector } from 'react-redux'
import { FaCaretRight } from 'react-icons/fa'
import RichTextRenderer from 'components/RichTextRenderer'
import Container from 'components/Container'
import SquareImage from 'components/SquareImage'
import Seo from 'components/Seo'
import PostLayout from 'components/PostLayout'
import Button from 'components/Button'
import PodcastLinks from 'components/PodcastLinks'
import SubscribeBox from 'components/SubscribeBox'
import Speaker from 'components/Speaker'
import PageType from 'components/PageType'
import PostInfo from 'components/PostInfo'
import Share from 'components/Share'
import {
  setCurrentEpisodeId,
  setIsPlaying,
  setEpisodeCurrentProgress,
} from 'state/actions'
import { sizes } from 'styles'

const StyledPageType = styled(PageType)`
  margin-bottom: 30px;
`

const StyledPostInfo = styled(PostInfo)`
  margin-bottom: 40px;
`

const Title = styled.h1`
  font-size: 30px;
  line-height: 36px;
  font-family: ${({ theme }) => theme.font.text};
  margin-bottom: 20px;

  @media ${sizes.tabletPortrait} {
    font-size: 40px;
    line-height: 46px;
  }

  @media ${sizes.tabletLandscape} {
    margin-bottom: 44px;
  }
`

const MobileContainer = styled.div`
  width: 80%;

  @media ${sizes.tabletLandscape} {
    display: none;
  }
`

const MobileImage = styled(SquareImage)`
  margin-bottom: 20px;

  @media ${sizes.tabletLandscape} {
    margin-bottom: 0;
  }
`

const MobileActions = styled.div`
  display: flex;
  ${({ hasLinks }) =>
    !hasLinks &&
    css`
      flex-direction: column;
    `}
`

const Aside = styled.div`
  display: none;

  @media ${sizes.tabletLandscape} {
    display: block;

    > :not(:first-child) {
      margin-top: 40px;
    }
  }
`

const ActionsContainer = styled.div`
  display: flex;
  flex-direction: column-reverse;
  align-items: flex-start;

  > :last-child {
    margin-bottom: 20px;
  }

  @media ${sizes.tabletLandscape} {
    flex-direction: row;
    justify-content: space-between;
    align-items: center;

    > :last-child {
      margin-bottom: 0;
    }
  }
`

const StyledShare = styled(Share)`
  margin-top: 20px !important;
  ${({ alignRight }) =>
    alignRight &&
    css`
      float: right;
    `}
`

const MobileShare = styled(Share)`
  align-self: ${({ alignRight }) => (alignRight ? 'flex-end' : 'flex-start')};
  margin-left: ${({ alignRight }) => (alignRight ? '10px' : '0')};
`

const StyledSubscribeBox = styled(SubscribeBox)`
  width: 100%;
  max-width: 350px;
  margin-top: 40px;
  margin-bottom: 40px;

  @media ${sizes.tabletLandscape} {
    margin-top: 80px;
  }
`

const BodyText = styled.div`
  > h3 {
    font-size: 24px;
  }
`

const SpeakersTitle = styled.h3`
  font-size: 22px;
  margin-top: 40px;
  margin-bottom: 30px;
`

const Actions = ({
  episodeId,
  applePodcastsLink,
  googlePodcastsLink,
  spotifyLink,
  iHeartRadioLink,
}) => {
  const dispatch = useDispatch()
  return (
    <ActionsContainer>
      <PodcastLinks
        applePodcastsLink={applePodcastsLink}
        googlePodcastsLink={googlePodcastsLink}
        spotifyLink={spotifyLink}
        iHeartRadioLink={iHeartRadioLink}
      />
      <Button
        variant="secondary"
        onClick={() => {
          dispatch(setCurrentEpisodeId(episodeId))
          dispatch(setEpisodeCurrentProgress(episodeId, 0))
          dispatch(setIsPlaying(true))
        }}
      >
        Play episode
        <FaCaretRight />
      </Button>
    </ActionsContainer>
  )
}

const Episode = ({ data }) => {
  const { contentfulPodcastEpisode } = data
  const {
    id,
    title,
    description,
    episodeNumber,
    publishedOn,
    tags,
    image,
    applePodcastsLink,
    googlePodcastsLink,
    spotifyLink,
    iHeartRadioLink,
    bodyText,
    speakers,
  } = contentfulPodcastEpisode
  const episodeDurations = useSelector((state) => state.episodeDurations)
  const episodeDuration = episodeDurations[id]

  const getContent = () => (
    <>
      <StyledPageType
        type="Podcast"
        identifier={`Ep. ${episodeNumber}`}
        linkTo="/podcast"
      />
      <StyledPostInfo
        publishedOn={publishedOn}
        tags={tags}
        episodeDuration={episodeDuration}
      />
      <Title>{title}</Title>
      <MobileContainer>
        <MobileImage image={image} />
        <MobileActions
          hasLinks={
            applePodcastsLink ||
            googlePodcastsLink ||
            spotifyLink ||
            iHeartRadioLink
          }
        >
          <Actions
            episodeId={id}
            applePodcastsLink={applePodcastsLink}
            googlePodcastsLink={googlePodcastsLink}
            spotifyLink={spotifyLink}
            iHeartRadioLink={iHeartRadioLink}
          />
          <MobileShare
            alignRight={
              applePodcastsLink ||
              googlePodcastsLink ||
              spotifyLink ||
              iHeartRadioLink
            }
            shareTitle={title}
            shareLink={typeof window !== 'undefined' ? window.location : ''}
          />
        </MobileActions>
      </MobileContainer>
      {description && <p>{description.description}</p>}
      {bodyText && (
        <BodyText>
          <RichTextRenderer richText={bodyText.json} />
        </BodyText>
      )}
      {speakers && <SpeakersTitle>About your hosts</SpeakersTitle>}
      {speakers.map((speaker, i) => (
        <Speaker speaker={speaker} key={i} />
      ))}
      <StyledSubscribeBox />
    </>
  )

  const getAsideContent = () => (
    <Aside>
      <SquareImage image={image} />
      <Actions
        episodeId={id}
        applePodcastsLink={applePodcastsLink}
        googlePodcastsLink={googlePodcastsLink}
        spotifyLink={spotifyLink}
        iHeartRadioLink={iHeartRadioLink}
      />
      <StyledShare
        alignRight={
          applePodcastsLink ||
          googlePodcastsLink ||
          spotifyLink ||
          iHeartRadioLink
        }
        shareTitle={title}
        shareLink={typeof window !== 'undefined' ? window.location : ''}
      />
    </Aside>
  )

  return (
    <Container>
      <Seo
        title={title}
        image={{
          src: image?.resize?.src,
          width: 1600,
          height: 900,
        }}
        description={
          description
            ? description.description.substr(0, 157) + '...'
            : undefined
        }
      />
      <PostLayout mainContent={getContent()} asideContent={getAsideContent()} />
    </Container>
  )
}

export const episodeQuery = graphql`
  query episodeQuery($episodeNumber: Int!) {
    contentfulPodcastEpisode(episodeNumber: { eq: $episodeNumber }) {
      id
      title
      episodeNumber
      publishedOn
      tags
      image {
        fluid {
          ...GatsbyContentfulFluid
        }
        resize(width: 1600, height: 900) {
          src
        }
      }
      speakers {
        name
        picture {
          fluid {
            ...GatsbyContentfulFluid
          }
        }
        title
        company
        description {
          json
        }
        linkedInLink
        twitterLink
        webPageLink
      }
      description {
        description
      }
      bodyText {
        json
      }
      applePodcastsLink
      googlePodcastsLink
      spotifyLink
      iHeartRadioLink
    }
  }
`

export default Episode
